<template>
  <div>

    <transition-group
      name="staggered-fade"
      v-bind:css="false"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
    >
      <div v-for="(funnel, index) in funnels" :key="'row' + index" :data-index="index" class="columns">
        <div class="column px-2 py-2 is-one-fifth">
          <div style="height: 40px; background: #cccccc;" class="mb-2 is-size-7">
            <div>Step: {{ funnel.Step }}</div>
            <div>{{ getLabel(index) }}</div>
          </div>
        </div>
        <div class="column px-2 py-2" style="display:flex; justify-content: center; width=100%; background:#ffffff">
          <div :style="`display:block; text-align: center; width:${100 - ((10 + (index + 1)) * index)}%; height: 40px; background:${getBackground(index)};`" class="mb-2 is-size-7">
            <div style="text-align: center;">{{ funnel.Entered }}</div>
            <div style="text-align: center;">{{ funnel.Percentage }}%</div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>

</template>

<script>
import statsProvider from '@/providers/stats'
import staggeredAnimation from '@/mixins/staggeredAnimation'

export default {
  components: {},

  props: {
    funnels: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  mixins: [staggeredAnimation],

  data() {
    return {
      isLoading: false,
      colours: ['#ffff4d', '#ffc14d', '#ea9a62', '#ff4d4d', '#ff6680', '#ff4dff'],
      labels: ['Start', 'Spaces', 'Options', 'Info', 'Billing', 'Booked'],
    }
  },

  computed: {
    totalSearch() {
      let total = 0

      if (this.funnels.length > 0) {
        total = this.funnels[0].Entered
      }

      return total
    }
  },

  created() {
    //this.getSearchFunnel()
  },

  methods: {
    getSearchFunnel() {
      statsProvider.methods
        .getLocationSearchFunnel(
          this.locationId,
          this.month,
          this.year,
          this.channelId,
          this.widgetId,
          this.meetingtypeId,
          this.showBackend
        )
        .then((response) => {
          if (response.status === 200) {
            this.funnels = Object.freeze(response.data)
          }
        })
        .catch(err => {})
        .finally(() => {
          this.isLoading = false
        })
    },

    getBackground(index) {
      return this.colours[index]
    },

    getLabel(index) {
      return this.labels[index]
    },

    calculateStepPercentage(entered) {
      let percentage = 0
      if (entered > 0 && this.totalSearch > 0) {
        percentage = (entered / this.totalSearch) * 100
      }

      return this.roundToOne(percentage)
    },

    roundToOne(num) {
      let self = this
      return +(Math.round(self.roundToTwo(num) + 'e+1') + 'e-1')
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    },
  }
}
</script>

<style></style>